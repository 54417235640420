import React from "react";
import BannerIMG from "../assets/banner-04.jpg"

const Banner = () => {
  return (
    <section
      class="py-6 py-md-16 py-lg-20 bg-img cover-background parallax dark-overlay"
      data-overlay-dark="85"
      style={{ backgroundImage: BannerIMG }}
    >
      <div class="container pt-1-9 pt-sm-6 pt-md-0 mt-n1-9">
        <div class="row align-items-center position-relative z-index-1 justify-content-center py-xxl-7">
          <div class="col-md-10 col-xl-11 text-center py-5">
            <span class="text-primary text-uppercase small letter-spacing-4 d-block mb-2 font-weight-600 wow fadeInUp">
              Register . Choose . Grow
            </span>
            <h1
              class="display-9 display-md-4 display-lg-3 display-xl-1 mb-2-2 text-shadow text-white font-weight-800 wow fadeInUp"
              data-wow-delay="200ms"
            >
              Your Trusted Patner for {" "}
              <span class="title-sm banner">All Agriculture Needs.</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="position-absolute top-5 right-n20 right-lg-n15 right-xl-n5 ani-left-right z-index-9 d-none d-md-block">
        <img src="img/content/shape-09.png" alt="..." />
      </div>
    </section>
  );
};

export default Banner;
