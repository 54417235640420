import React from "react";

const Mission = () => {
  return (
    <section className="clients-style3">
      <div className="container position-relative z-index-9">
        <div className="row align-items-center mt-n5">
          <div className="col-lg-6 mt-5">
            <div className="position-relative z-index-9">
              <div className="row">
                <div className="col-6 wow fadeInDown" data-wow-delay="200ms">
                  <div className="image-hover position-relative overflow-hidden">
                    <img src="img/content/mission.jpg" alt="..." />
                  </div>
                </div>
                <div className="col-6 wow fadeInUp" data-wow-delay="400ms">
                  <div className="image-hover position-relative overflow-hidden">
                    <img
                      src="img/content/vision.jpg"
                      alt="..."
                      className="mt-1-9 mt-sm-10"
                    />
                  </div>
                </div>
              </div>
              <div
                className="sm-box-wrapper z-index-9 wow fadeIn"
                data-wow-delay="600ms"
              >
                <div className="inner-box">
                  <h3 className="h4 mb-0 text-white">Vision & Mission</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-5 wow fadeIn" data-wow-delay="400ms">
            <div className="ps-lg-6 ps-xl-12">
              <div className="mb-1-9 position-relative">
                <span className="text-primary text-uppercase small letter-spacing-4 d-block mb-2 font-weight-600 wow fadeInUp">
                  Mission & Vision.
                </span>
                <h2
                  className="display-22 display-sm-18 display-md-16 display-lg-14 display-xl-10 mb-lg-1-6 wow fadeInUp"
                  data-wow-delay="200ms"
                >
                  We help to achieve <span className="title-sm">mutual goals.</span>
                </h2>
              </div>
              <p className="mb-1-9 wow fadeInUp" data-wow-delay="200ms">
                Our Vision and Mission are both empowering our group to achieve
                the objective. Our mission is to help grow the agriculture sector along with helping in growth of India.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="position-absolute top-n5 right-n10 right-xxl-n5 ani-top-bottom d-none d-xl-block">
        <img src="img/content/shape-05.png" alt="..." />
      </div>
    </section>
  );
};

export default Mission;
