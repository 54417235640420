import React from "react";

const InformationBar = () => {
  return (
    <div className="top-bar bg-primary">
      <div className="container-fluid px-lg-1-6 px-xl-2-5 px-xxl-2-9">
        <div className="row">
          <div className="col-md-9 col-xs-12">
            <div className="top-bar-info">
              <ul className="ps-0">
                <li>
                  <i className="ti-mobile"></i>(+91) 8143272975
                </li>
                <li className="d-none d-sm-inline-block">
                  <i className="ti-email"></i>contact@mercifarmer.com
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-md-3 d-none d-md-block">
            <ul className="top-social-icon ps-0">
              <li>
                <a href="#!">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationBar;
