import React from "react";

const Wedo = () => {
  return (
    <>
      <section className="about-style-01">
        <div className="container">
          <div className="row align-items-xl-center mt-n7">
            <div className="col-lg-6 mt-7">
              <div className="position-relative z-index-9">
                <div className="row">
                  <div className="col-6 wow fadeInDown" data-wow-delay="200ms">
                    <div className="image-hover position-relative overflow-hidden">
                      <img src="img/content/farmer-3.jpg" alt="..." />
                    </div>
                  </div>
                  <div className="col-6 wow fadeInUp" data-wow-delay="400ms">
                    <div className="image-hover position-relative overflow-hidden">
                      <img
                        src="img/content/farmer-1.jpg"
                        alt="..."
                        className="mt-1-9 mt-sm-10"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="sm-box-wrapper z-index-9 wow fadeIn"
                  data-wow-delay="600ms"
                >
                  <div className="inner-box">
                    <span className="exp-no">100+</span>
                    <span className="exp-year">
                      Farmers got funds <br /> from Digital Farmers. 
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-7 wow fadeIn">
              <div className="ps-lg-1-9 ps-xl-7">
                <div className="mb-1-9 position-relative">
                  <span className="text-primary text-uppercase small letter-spacing-4 d-block mb-2 font-weight-600 wow fadeInUp">
                    Grow with us.
                  </span>
                  <h2
                    className="display-22 display-sm-18 display-md-16 display-lg-14 display-xl-10 mb-lg-1-6 wow fadeInUp"
                    data-wow-delay="200ms"
                  >
                    An agency created {" "}
                    <span className="title-sm">for farmers.</span>
                  </h2>
                </div>
                <p
                  className="fst-italic font-weight-900 w-90 wow fadeInUp"
                  data-wow-delay="200ms"
                >
                  Register with us as a farmer and get benifits of funds for farming.
                  Register with us as a Digital Farmer, invest in agriculture and help in growth of India. 
                </p>
                <p
                  className="mb-1-9 border-bottom pb-1-9 wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  Team is a diverse network of consultants and industry
                  professionals with a global mindset and a collaborative
                  culture. We work to understand your issues and are driven to
                  ask better questions.
                </p>
                <div className="d-sm-flex align-items-center">
                  <div className="flex-shrink-0 mb-4 mb-sm-0">
                    
                    <span
                      className="bg-primary about-icon text-white wow fadeInRight"
                      data-wow-delay="400ms"
                    >
                      <i className="fa-solid fa-phone"></i>
                    </span>
                  </div>
                  <div
                    className="flex-grow-1 ms-sm-4 wow fadeIn"
                    data-wow-delay="600ms"
                  >
                    <h4 className="mb-0 h5">
                      Over 500 people of our people work for you in more than 10 states in India.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute top-5 left-n5 ani-top-bottom d-none d-md-block">
          <img src="img/content/shape-05.png" alt="..." />
        </div>
      </section>
      <section className="bg-secondary">
        <div className="container position-relative z-index-9">
          <div
            className="mb-2-9 mb-lg-6 position-relative text-center wow fadeInUp"
            data-wow-delay="200ms"
          >
            <span className="text-primary text-uppercase small letter-spacing-4 d-block mb-2 font-weight-600">
              What We Do.
            </span>
            <h2 className="display-22 display-sm-18 display-md-16 display-lg-14 display-xl-10 text-white mb-lg-1-6">
              Services that will help <span className="title-sm">you grow.</span>
            </h2>
          </div>
          <div className="row g-5 mt-n1-9">
            <div
              className="col-md-6 col-lg-4 mt-1-9 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="card card-style06 border-0">
                <div className="card-front">
                  <div className="card-inner">
                    <div className="mb-4 border-bottom pb-4 border-color-light-white">
                      <img src="img/icons/18.png" alt="..." className="mb-4" />
                      <h3 className="h5 mb-0">
                        <a href="services.html" className="text-white">
                          Global Online Market
                        </a>
                      </h3>
                    </div>
                    <a href="#!" className="text-white">
                      Read More
                      <i className="fa-solid fa-arrow-right ps-3 text-primary"></i>
                    </a>
                  </div>
                  <div className="card-body pe-4 bg-primary">
                    <p className="text-white display-27 lh-base">
                      We have created a platform where farmers can have oppurtunity to improve themselves.
                    </p>
                    <a href="services.html" className="text-white">
                      Read More
                      <i className="fa-solid fa-arrow-right ps-3 text-white"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mt-1-9 wow fadeInUp"
              data-wow-delay="600ms"
            >
              <div className="card card-style06 border-0">
                <div className="card-front">
                  <div className="card-inner">
                    <div className="mb-4 border-bottom pb-4 border-color-light-white">
                      <img src="img/icons/19.png" alt="..." className="mb-4" />
                      <h3 className="h5 mb-0">
                        <a href="services.html" className="text-white">
                          Branding Services
                        </a>
                      </h3>
                    </div>
                    <a href="#!" className="text-white">
                      Read More
                      <i className="fa-solid fa-arrow-right ps-3 text-primary"></i>
                    </a>
                  </div>
                  <div className="card-body pe-4 bg-primary">
                    <p className="text-white display-27 lh-base">
                      We developed an ecommerce web app, where farmers can directly interact with global market.
                    </p>
                    <a href="services.html" className="text-white">
                      Read More
                      <i className="fa-solid fa-arrow-right ps-3 text-white"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mt-1-9 wow fadeInUp"
              data-wow-delay="800ms"
            >
              <div className="card card-style06 border-0">
                <div className="card-front">
                  <div className="card-inner">
                    <div className="mb-4 border-bottom pb-4 border-color-light-white">
                      <img src="img/icons/20.png" alt="..." className="mb-4" />
                      <h3 className="h5 mb-0">
                        <a href="services.html" className="text-white">
                          Digital Farmer
                        </a>
                      </h3>
                    </div>
                    <a href="#!" className="text-white">
                      Read More
                      <i className="fa-solid fa-arrow-right ps-3 text-primary"></i>
                    </a>
                  </div>
                  <div className="card-body pe-4 bg-primary">
                    <p className="text-white display-27 lh-base">
                      Any one from any where can become a digital farmer by investing in the crops cycle.
                    </p>
                    <a href="services.html" className="text-white">
                      Read More
                      <i className="fa-solid fa-arrow-right ps-3 text-white"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute top-0 left-0 opacity2 shape-img-one">
          <img src="img/bg/bg-05.png" alt="..." />
        </div>
      </section>
      <section class="portfolio-style02 py-0">
        <div class="pt-6 pb-16 pt-sm-10 pb-sm-20 pb-xl-22 portfolio-bg bg-primary">
          <div class="container position-relative z-index-9">
            <div class="row mt-n1-9">
              <div
                class="col-sm-6 col-lg-3 mt-1-9 text-center wow fadeInUp"
                data-wow-delay="100ms"
              >
                <h3 class="h1 text-white">
                  <span class="countup">100</span>+
                </h3>
                <span class="text-white text-uppercase letter-spacing-2">
                  Farmers
                </span>
              </div>
              <div
                class="col-sm-6 col-lg-3 mt-1-9 text-center wow fadeInUp"
                data-wow-delay="200ms"
              >
                <h3 class="h1 text-white">
                  <span class="countup">15</span>+
                </h3>
                <span class="text-white text-uppercase letter-spacing-2">
                  States
                </span>
              </div>
              <div
                class="col-sm-6 col-lg-3 mt-1-9 text-center wow fadeInUp"
                data-wow-delay="300ms"
              >
                <h3 class="h1 text-white">
                  <span class="countup">500</span>+
                </h3>
                <span class="text-white text-uppercase letter-spacing-2">
                  Employees
                </span>
              </div>
              <div
                class="col-sm-6 col-lg-3 mt-1-9 text-center wow fadeInUp"
                data-wow-delay="400ms"
              >
                <h3 class="h1 text-white">
                  <span class="countup">50</span>+
                </h3>
                <span class="text-white text-uppercase letter-spacing-2">
                  Digital Farmers
                </span>
              </div>
            </div>
          </div>
          <div class="position-absolute top-0 end-0">
            <img src="img/content/shape-07.png" alt="..." />
          </div>
        </div>
      </section>
    </>
  );
};

export default Wedo;
