import React from "react";
import Banner from "./Components/Banner";
import Footer from "./Components/Footer";
import InformationBar from "./Components/InformationBar";
import Mission from "./Components/Mission";
import Navbar from "./Components/Navbar";
import Wedo from "./Components/Wedo";

function App() {
  return (
    <div className="main-wrapper">
      <header className="header-style2">
        <InformationBar />
        <Navbar />
      </header>
      <Banner />
      <Wedo />
      <Mission />
      <Footer />
    </div>
  );
}

export default App;
