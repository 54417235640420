import React from "react";

const Navbar = () => {
  return (
    <div className="container-fluid px-lg-1-6 px-xl-2-5 px-xxl-2-9">
      <div className="row align-items-center">
        <div className="col-12 col-lg-12">
          <div className="menu_area alt-font">
            <nav className="navbar navbar-expand-lg navbar-light p-0">
              <div className="navbar-header navbar-header-custom">
                <a href="index.html" className="navbar-brand logodefault">
                  <img id="logo" src="img/logos/logo.png" alt="logo" />
                </a>
              </div>

              <div className="navbar-toggler"></div>

              <ul className="navbar-nav ms-auto" id="nav">
                <li>
                  <a href="#!">Home</a>
                </li>
                <li>
                  <a href="#!">About Us</a>
                </li>
                <li>
                  <a href="#!">Our Plans</a>
                  <ul>
                        <li><a href="#!">Rythu Ratna</a></li>
                        <li><a href="#!">Rythu Chakra</a></li>
                    </ul>
                </li>
                <li>
                  <a href="#!">Digital Farmer</a>
                </li> 
                <li>
                  <a href="#!">Our Team</a>
                </li>
                <li>
                  <a href="#!">Contact Us</a>
                </li>
                
              
              </ul>
              <div className="attr-nav align-items-xl-center ms-xl-auto main-font">
                <ul>
                  <li className="d-none d-xl-inline-block">
                    <a href="contact.html" className="btn-style2 md text-white">
                     Register With Us
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
